import {
  ArItems,
  BaggageSvg,
  Box,
  Button,
  ButtonVariant,
  Card,
  CardContent,
  CardHeader,
  EFont,
  ESpacing,
  ETripType,
  Font,
  IBEPax,
  StatusWarningIcon,
  Tag,
  TripDetail,
  useMediaQueries,
} from "@hkexpressairwayslimited/ui";
import { Grid, Stack, SvgIcon } from "@mui/material";
import { useTransContent } from "modules/common/trans-content/transContent";
import { calcAllPickedArItems } from "modules/features/flightBooking/calculateCost/calcAllPickedArItems";
import { SelectedArItems } from "modules/features/flightBooking/reducers";
import { useCurrency } from "modules/global/currency/useCurrency";
import { useMemo, useRef, useState } from "react";
import { OnBaggageSelectedData } from "..";
import PopUpModal from "../../flight-select/pop-up-modal/PopUpModal";
import { InvalidMessagePopUp } from "../InvalidMessagePopUp";
import { JourneyBaggageSummary } from "./JourneyBaggageSummary";
import { JourneySection } from "./JourneySection";

export function BaggageSection({
  tripDetail,
  passengers,
  tripType,
  onConfirm,
  error,
  onBaggageSelected,
  noPreSelection = true,
  selectedArItem = [],
  baggageConfirmed = false,
  noTags = true,
}: {
  tripDetail: TripDetail;
  passengers: IBEPax[];
  tripType: ETripType;
  onConfirm: (b: boolean) => void;
  error: boolean;
  onBaggageSelected: (data: OnBaggageSelectedData) => void;
  noPreSelection?: boolean;
  selectedArItem: SelectedArItems[];
  baggageConfirmed: boolean;
  noTags?: boolean;
}) {
  const [open, setOpen] = useState(false);
  // const baggageCost = useJourneyCost(
  //   EArItemType.BAGGAGE,
  //   tripDetail.journeys.reduce((a: ArItems[], c) => {
  //     a.push(c.arItems);
  //     return a;
  //   }, [])
  // );
  const [confirmed, setConfirmed] = useState(baggageConfirmed);
  const selectedBaggageCost = useMemo(
    () =>
      calcAllPickedArItems(
        selectedArItem?.reduce((a: ArItems[], c) => {
          a.push({
            available: tripDetail.journeys.find((e) => e.journey_key === c.journeyKey)?.arItems.available ?? [],
            selected: c.arItems ?? [],
            purchased: [],
          });
          return a;
        }, []),
        undefined,
        true
      ),
    [selectedArItem, tripDetail.journeys]
  );
  function handleBaggageConfirm() {
    if (!confirmed && selectedBaggageCost === 0) {
      setOpen(true);
    } else {
      setConfirmed(!confirmed);
      onConfirm && onConfirm(!confirmed);
    }
  }
  const wrapRef = useRef<HTMLDivElement>(null);
  const { t } = useTransContent();
  const { P } = useCurrency();
  const { isMobile } = useMediaQueries();
  return (
    <>
      <Card sx={{ my: ESpacing._sm }} size={isMobile ? undefined : "l"}>
        <CardHeader
          avatar={
            <SvgIcon sx={{ color: "purple.default", width: "32px", height: "32px" }}>
              <BaggageSvg />
            </SvgIcon>
          }
          title={
            <Stack direction={["column", "column", "row"]} columnGap={2}>
              <Font variant={EFont.h3} fontWeight='fontWeightBold' mt='3px'>
                {t("web.addExtras.baggage.title")}
              </Font>
              <Box display={noTags ? "none" : "block"}>
                <Tag
                  value={t("web.addExtras.baggage.promoTag")}
                  variant='promo_tag'
                  sx={{
                    fontWeight: "fontWeightBold",
                    maxWidth: ["110px", "110px", "none"],
                    padding: [
                      `${ESpacing._3xs} ${ESpacing._xs} !important`,
                      `${ESpacing._3xs} ${ESpacing._xs} !important`,
                      undefined,
                    ],
                  }}
                ></Tag>
              </Box>
            </Stack>
          }
        ></CardHeader>
        <CardContent>
          <Font sx={{ marginBottom: ESpacing._sm, display: confirmed ? "none" : "block" }}>
            {t("web.addExtras.baggage.content")}
          </Font>
          <Grid container spacing={ESpacing._sm}>
            {tripDetail.journeys.map((e, idx) => (
              <Grid item key={e.journey_key} xs={12} md={6}>
                <JourneySection
                  tripId={tripDetail.id}
                  noPreSelection={noPreSelection}
                  summary={confirmed ? <JourneyBaggageSummary journey={e} passengers={passengers} /> : undefined}
                  journey={e}
                  title={
                    tripType === ETripType.roundTrip && !e.index
                      ? idx === 0
                        ? `${t("web.flightBook.flightSelect.flightDetailPopUp.Depart")}`
                        : `${t("web.flightBook.flightSelect.flightDetailPopUp.Return")}`
                      : tripType === ETripType.roundTrip && e.index
                        ? e.index === 0
                          ? `${t("web.flightBook.flightSelect.flightDetailPopUp.Depart")}`
                          : `${t("web.flightBook.flightSelect.flightDetailPopUp.Return")}`
                        : tripType === ETripType.oneWay
                          ? `${t("web.flightBook.flightSelect.flightDetailPopUp.Depart")}`
                          : !e.index
                            ? idx === 0
                              ? `${t("web.flightBook.flightSelect.flightDetailPopUp.Depart")}`
                              : `${t("web.flightBook.flightSelect.flightDetailPopUp.Return")}`
                            : e.index === 0
                              ? `${t("web.flightBook.flightSelect.flightDetailPopUp.Depart")}`
                              : `${t("web.flightBook.flightSelect.flightDetailPopUp.Return")}`
                  }
                  passenger={passengers}
                  onBaggageSelect={onBaggageSelected}
                  selectedArItem={selectedArItem.find((n) => n.journeyKey === e.journey_key)?.arItems ?? []}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Card withBGC borderless={!error} bodC={error ? "error" : "default"} size='m'>
                <CardContent>
                  <Stack
                    sx={{
                      flexDirection: "row",
                    }}
                    justifyContent='space-between'
                  >
                    <Stack
                      direction={["column", "column", "row"]}
                      alignItems={["flex-start", "flex-start", "center"]}
                      columnGap={ESpacing._2xs}
                    >
                      <Font>{t("web.addExtras.baggage.subtotal")}</Font>
                      <Font variant={EFont.h3} fontWeight='fontWeightBold'>
                        {P(selectedBaggageCost, undefined, false, 0, true)}
                      </Font>
                    </Stack>
                    <Button
                      style={{ height: "40px", alignSelf: "center" }}
                      onClick={handleBaggageConfirm}
                      variant={ButtonVariant.Secondary}
                    >
                      <Font>
                        {confirmed ? t("web.addExtras.baggage.button.edit") : t("web.addExtras.baggage.button.confirm")}
                      </Font>
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
              {error && (
                <Stack
                  mt={ESpacing._xs}
                  spacing={ESpacing._2xs}
                  justifyItems='center'
                  direction='row'
                  sx={{ color: "critical.default" }}
                >
                  <SvgIcon>
                    <StatusWarningIcon />
                  </SvgIcon>
                  <Font>{t("web.addExtras.baggage.confirm")}</Font>
                </Stack>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <PopUpModal maxWidth='xs' open={open} setOpen={setOpen}>
        <InvalidMessagePopUp
          onConfirm={() => {
            setOpen(false);
            onConfirm && onConfirm(true);
            setConfirmed(true);
          }}
          onCancel={() => {
            setOpen(false);
          }}
          baggageError={true}
        />
      </PopUpModal>
    </>
  );
}
