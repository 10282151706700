import { formatISO } from "date-fns";
import { B2BUnsettledPaymentOrderCreation } from "lib/features/b2b/agent-portal/service";
import {
  CreateOrderResponse,
  CreatePaymentApiProps,
  ErrorType,
  GetOrderStatusResponse,
  PaymentMethod,
} from "lib/features/flight-book/payment/definition";
import { isCCMethod } from "lib/features/flight-book/payment/helper";
import {
  cancelPayment,
  createCCpayment,
  createPayment,
  creditDataExchange,
  encryptWithJwk,
  generateSignature,
  getOrderStatus,
  getPaymentCallbackUrl,
} from "lib/features/flight-book/payment/service";
import { ManageMyBookingTripDetail } from "lib/features/manage-my-booking/my-trips/definition";
import { InitMMBPaymentSaga } from "lib/features/manage-my-booking/my-trips/payment/definition";
import { constructUnsettledPaymentOrderCreationBody } from "lib/features/manage-my-booking/my-trips/payment/helper";
import {
  closeLoadingBackdrop,
  showLoadingBackdrop,
} from "modules/common/loading-backdrop/actions/LoadingBackdropAction";
import { Effect, call, cancel, delay, fork, put, take, takeEvery } from "redux-saga/effects";
import { RouterInstance } from "router/router-utils";
import { storeCCPaymentMetaData } from "store/sessionStorage/slices/ccPaymentMetaData";
import { storeErrorModalDetail } from "store/sessionStorage/slices/errorModalDetail";
import { v4 } from "uuid";
import { INIT_B2B_MMB_UNSETTLE_PAYMENT_SAGA, createB2BMMBFormAction } from "../actions";

function* createOrderId(
  mileAmount = 0,
  cash_amount = 0,
  currency: string,
  tripCost: number,
  booking: ManageMyBookingTripDetail,
  pay_by_organization_credit: boolean
): Generator<Effect, void, any> {
  try {
    const requestBody = yield call(
      constructUnsettledPaymentOrderCreationBody,
      booking,
      cash_amount,
      pay_by_organization_credit
    );

    const { order_id, order_type }: CreateOrderResponse = yield call(B2BUnsettledPaymentOrderCreation, requestBody);
    if (order_id) {
      yield put({ type: "CREATE_PAYMENT_SAGA", order_id, order_type });
    } else {
      yield put({ type: "CANCEL_PAYMENT_SAGA" });
    }
  } catch (e) {
    yield put({ type: "CANCEL_PAYMENT_SAGA" });
    yield put(closeLoadingBackdrop());
  }
}

function* initCCpayment(
  { creditCardDetail, paymentMethod }: InitMMBPaymentSaga,
  order_id: string
): Generator<Effect, void, any> {
  if (!creditCardDetail) return;
  const dataExchangeResp = yield call(creditDataExchange, {
    AccountNumber: creditCardDetail.cardNumber.replace(/\s/g, ""),
  });

  if (dataExchangeResp.jwt) {
    yield put(storeCCPaymentMetaData({ creditDataExchange: dataExchangeResp }));
  }
}

function* createCCpaymentSaga(
  paymentDetail: InitMMBPaymentSaga,
  order_id: string,
  order_type: string,
  tds_md: string,
  afy_tid: string
): Generator<Effect, void, any> {
  const { creditCardDetail, paymentMethod, convenience_fee = 0, ccToken, dcc_key, dcc_accept_offer } = paymentDetail;
  if (!creditCardDetail || !ccToken) return;
  const { cardNumber, expiryDate, cardHolderName, cv2Number } = creditCardDetail;
  const nonce = v4();
  const timestamp = formatISO(new Date());
  const CCPaymentRequestBody = {
    order_id: order_id,
    device_type: "WEB",
    locale: "en_hk",
    success_url: getPaymentCallbackUrl(1, paymentDetail.paymentMethod),
    failure_url: getPaymentCallbackUrl(0, paymentDetail.paymentMethod),
    payment_option: paymentMethod,
    convenience_fee,
    cc_payload: {
      dcc_key,
      dcc_accept_offer,
      card_number: encryptWithJwk(ccToken, cardNumber.replace(/\s/g, "")),
      card_expiry: encryptWithJwk(ccToken, expiryDate),
      card_cvc: encryptWithJwk(ccToken, cv2Number),
      card_holder_name: cardHolderName,
      nonce: nonce,
      timestamp: timestamp,
      sig: generateSignature(cardNumber.replace(/\s/g, ""), expiryDate, cv2Number, nonce, timestamp),
      tds_md,
      afy_tid,
    },
  };
  const rest = yield call(createCCpayment, CCPaymentRequestBody, true);
  if (rest.txn_id) {
    // yield call(retrieveOrderStatus, order_id);
    yield put({ type: "RETRIEVE_ORDER_STATUS" });
  } else {
    yield put({ type: "CANCEL_PAYMENT_SAGA" });
    yield put(
      storeErrorModalDetail({
        type: ErrorType.M,
        title: `${rest?.error?.error_code}.title`,
        desc: `${rest?.error?.error_code}.desc`,
        onClose: async () => {
          await cancelPayment(order_id);
        },
        data: {
          trace_id: rest.metadata.trace_id,
          tReplaceData: {
            title: {
              times: retryCount,
            },
            desc: {},
          },
        },
      })
    );
  }
  return rest;
}

function* createPaymentSaga(
  order_id: string,
  order_type: string,
  paymentDetail: InitMMBPaymentSaga
): Generator<Effect, void, any> {
  const { paymentMethod, convenience_fee = 0 } = paymentDetail;
  if (!paymentMethod) {
    yield put({ type: "CANCEL_PAYMENT_SAGA" });
    return;
  }
  const rest = yield call(
    createPayment,
    {
      order_id: order_id,
      device_type: "WEB",
      locale: "en_hk",
      success_url: getPaymentCallbackUrl(1, paymentDetail.paymentMethod),
      failure_url: getPaymentCallbackUrl(0, paymentDetail.paymentMethod),
      payment_option: paymentMethod,
      convenience_fee,
    } as CreatePaymentApiProps,
    true
  );
  if (rest.txn_id) {
    // yield call(retrieveOrderStatus, order_id);
    yield put({ type: "RETRIEVE_ORDER_STATUS" });
  } else {
    yield put({ type: "CANCEL_PAYMENT_SAGA" });
    yield put(
      storeErrorModalDetail({
        type: ErrorType.M,
        title: `${rest?.error?.error_code}.title`,
        desc: `${rest?.error?.error_code}.desc`,
        onClose: async () => {
          await cancelPayment(order_id);
        },
        data: {
          trace_id: rest.metadata.trace_id,
          tReplaceData: {
            title: {
              times: retryCount,
            },
            desc: {},
          },
        },
      })
    );
  }
  return rest;
}
let retryCount = 1;
let remaining_payment_retry_count = 3;
function* retrieveOrderStatus(
  order_id: string,
  booking: ManageMyBookingTripDetail,
  order_type: string,
  paymentDetail?: Partial<InitMMBPaymentSaga>
): Generator<Effect, void, any> {
  while (true) {
    const response: GetOrderStatusResponse = yield call(getOrderStatus, order_id);
    remaining_payment_retry_count = response.remaining_payment_retry_count;
    if (response.cash_payment_status && response.cash_payment_status === "F_AUTH") {
      yield put({ type: "CANCEL_PAYMENT_SAGA" });

      if (retryCount >= 3 || remaining_payment_retry_count === 0) {
        yield put(
          storeErrorModalDetail({
            type: ErrorType.M,
            title: "web.payment.paymentErrorXTimes.error.title",
            desc: "web.payment.paymentErrorXTimes.error.desc",
            onClose: () => {
              RouterInstance.push(`/:lang/agent-portal/detail?bookingRef=${booking.sales_reference}`);
            },
            data: {
              trace_id: "web.payment.paymentErrorXTimes.error",
              tReplaceData: {
                title: {
                  times: retryCount,
                },
                desc: {},
              },
            },
          })
        );
      } else {
        yield put(
          storeErrorModalDetail({
            type: ErrorType.M,
            title: `web.payment.${response.cash_payment_status || response?.payment_error?.error_code}.title`,
            desc: `web.payment.${response.cash_payment_status || response?.payment_error?.error_code}.desc`,
            onClose: async () => {
              // await cancelPayment(order_id);
            },
            data: {
              trace_id: response.metadata.trace_id,
              tReplaceData: {
                title: {
                  times: 3 - retryCount,
                },
                desc: {},
              },
            },
          })
        );
      }
    } else {
      if (response.order_status === "F_COMP") {
        yield put(
          createB2BMMBFormAction({ form_action: response.form_action, form_url: response.form_value, order_id })
        );
        RouterInstance.push(`/:lang/agent-portal/detail?bookingRef=${booking.sales_reference}`);
        yield put({ type: "CANCEL_PAYMENT_SAGA" });
        break;
      } else if (response.order_status === "S_COMP") {
        yield put(
          createB2BMMBFormAction({
            form_action: response.form_action,
            form_url: response.form_value,
            order_id: order_id,
            order_status: "S_COMP",
          })
        );
        yield put(showLoadingBackdrop());
        RouterInstance.push("/:lang/confirmation?" + new URLSearchParams({ typeFlow: "B2B_UNSETTLE" }));
        yield put({ type: "CANCEL_PAYMENT_SAGA" });
        break;
      } else if (response.order_status === "PEND") {
        yield put(
          createB2BMMBFormAction({
            form_action: response.form_action,
            form_url: response.form_value,
            order_id: order_id,
            order_type,
            error: response.payment_error,
          })
        );
      }
    }
    yield delay(5000);
  }
}
function* initPaymentSaga(action: any): Generator<Effect, void, any> {
  yield put(showLoadingBackdrop());
  let createOrderTask = undefined;
  const {
    booking,
    cash_amount,
    mileAmount,
    paymentMethod,
    currency,
    tripCost,
    order_id: prev_order_id,
    order_type: prev_order_type,
  } = action.payload as InitMMBPaymentSaga;
  let payment_order_id = "";
  let payment_order_type = "";
  const pay_by_organization_credit = paymentMethod === PaymentMethod.AG ? true : false;
  if (!prev_order_id || !prev_order_type) {
    retryCount = 1;
    createOrderTask = yield fork(
      createOrderId,
      mileAmount,
      cash_amount,
      currency,
      tripCost,
      booking,
      pay_by_organization_credit
    );
    const { order_id, order_type } = yield take("CREATE_PAYMENT_SAGA");
    if (createOrderTask) {
      yield cancel(createOrderTask);
    }
    payment_order_id = order_id;
    payment_order_type = order_type;
  } else {
    if (retryCount >= 3 || remaining_payment_retry_count === 0) {
      yield put({ type: "CANCEL_PAYMENT_SAGA" });
      yield put(
        storeErrorModalDetail({
          type: ErrorType.M,
          title: "web.payment.paymentErrorXTimes.error.title",
          desc: "web.payment.paymentErrorXTimes.error.desc",
          onClose: () => {
            RouterInstance.push(`/:lang/agent-portal/detail?bookingRef=${booking.sales_reference}`);
          },
          data: {
            trace_id: "web.payment.paymentError3Times.error",
            tReplaceData: {
              title: {
                times: retryCount,
              },
            },
          },
        })
      );
      yield put(closeLoadingBackdrop());
      payment_order_id = "";
      payment_order_type = "";
    } else {
      retryCount++;
      payment_order_id = prev_order_id;
      payment_order_type = prev_order_type;
    }
  }

  let createPaymentTask: any;
  let orderStatusTask = undefined;
  if (payment_order_id || payment_order_type) {
    if (payment_order_type === "NO_PAYMENT_ORDER") {
      orderStatusTask = yield fork(retrieveOrderStatus, payment_order_id, booking, payment_order_type, action.payload);
    } else if (isCCMethod(paymentMethod)) {
      yield put(
        storeCCPaymentMetaData({
          orderNumber: payment_order_id,
          amount: cash_amount,
        })
      );
      createPaymentTask = yield fork(initCCpayment, action.payload, payment_order_id);
      const { md, afy_tid } = yield take("CREATE_CC_PAYMENT");
      yield fork(createCCpaymentSaga, action.payload, payment_order_id, payment_order_type, md, afy_tid);
    } else {
      createPaymentTask = yield fork(createPaymentSaga, payment_order_id, payment_order_type, action.payload);
    }

    if (!orderStatusTask) {
      yield take("RETRIEVE_ORDER_STATUS");
      orderStatusTask = yield fork(retrieveOrderStatus, payment_order_id, booking, payment_order_type, action.payload);
    }
  }
  yield take("CANCEL_PAYMENT_SAGA");
  yield put(closeLoadingBackdrop());
  yield cancel(createOrderTask);
  yield cancel(createPaymentTask);
  yield cancel(orderStatusTask);
}

export function* B2BMMBUnsettlePaymentSaga() {
  yield takeEvery(INIT_B2B_MMB_UNSETTLE_PAYMENT_SAGA, initPaymentSaga);
}
