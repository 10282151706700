import { ESpacing, ETripType, Font, IBEPax, Journey, PlaneSvg, TickCircleIcon } from "@hkexpressairwayslimited/ui";
import { Stack, SvgIcon } from "@mui/material";
import { getTripTitle } from "lib/features/flight-book/helper";
import { useTransContent } from "modules/common/trans-content/transContent";
import { useCallback } from "react";
import { EArItemType } from "..";
import { Summary } from "../OthersSection";

export function MealSummary({
  journeys,
  tripType,
  passengers,
}: {
  journeys: Journey[];
  tripType: ETripType;
  passengers: IBEPax[];
}) {
  const { mealSummary, noMeal } = formatMealSummary(journeys, tripType);
  const getFullName = useCallback(
    (paxId: string) => {
      const pax = passengers.find((e) => e.id === paxId);
      return `${pax?.surname} ${pax?.givenName}`;
    },
    [passengers]
  );
  const { t } = useTransContent();
  return (
    <Summary>
      <Stack direction='row' spacing={ESpacing._2xs}>
        <SvgIcon>
          <TickCircleIcon />
        </SvgIcon>

        <Stack spacing={ESpacing._2xs} width='100%'>
          <Font color='purple.default'>{t("web.addExtras.meal.detail")}</Font>
          {noMeal ? (
            <Font>{t("web.addExtras.meal.noMealAdd")}</Font>
          ) : (
            mealSummary.map((e) => (
              <Stack
                key={e.label}
                direction={{ xs: "column", md: "row" }}
                justifyContent='space-between'
                spacing={{ xs: 0, md: ESpacing._5xl }}
              >
                <Stack direction='row' spacing={ESpacing._2xs}>
                  <SvgIcon sx={{ color: "neutral.black" }}>
                    <PlaneSvg />
                  </SvgIcon>
                  <Font fontWeight='fontWeightBold'>
                    {t(`web.flightBook.flightSelect.flightDetailPopUp.${e.label}`)}
                  </Font>
                </Stack>
                <Stack flex='1' width='100%' spacing={ESpacing._2xs}>
                  {e.userSelected.length ? (
                    e.userSelected.map((e) => {
                      const paxId = Object.keys(e)[0];
                      return (
                        <Stack
                          direction='row'
                          alignItems='flex-start'
                          justifyContent='space-between'
                          key={e.label + "_" + paxId}
                        >
                          <Font>{getFullName(paxId)}</Font>
                          <Stack spacing={ESpacing._3xs}>
                            {Object.keys(e[paxId]).map((n) => (
                              <Stack
                                direction='row'
                                justifyContent='space-between'
                                spacing={[ESpacing._xs, ESpacing._5xl]}
                                key={n}
                              >
                                <Font>{t(`${n}.title`)}</Font>
                                <Font>x{e[paxId][n]}</Font>
                              </Stack>
                            ))}
                          </Stack>
                        </Stack>
                      );
                    })
                  ) : (
                    <Stack direction='row' alignItems='flex-start' justifyContent='space-between'>
                      <Font>{t("web.addExtras.meal.noMealAdd")}</Font>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            ))
          )}
        </Stack>
      </Stack>
    </Summary>
  );
}

function formatMealSummary(journeys: Journey[], tripType: ETripType) {
  const mealSummary: { label: string; userSelected: { [K: string]: { [K: string]: number } }[] }[] = [];
  let noMeal = true;
  journeys.forEach((e: Journey, idx: number) => {
    const userSelected: { [K: string]: { [K: string]: number } }[] = [];
    e.segments.forEach((e1) => {
      e1.arItems.selected
        .filter((e2) => e2.type === EArItemType.MEAL)
        .forEach((e3) => {
          e3.byPax?.forEach((n) => {
            noMeal = false;
            const mealIndex = userSelected.findIndex((e) => e[n.paxId]);
            if (mealIndex > -1 && userSelected[mealIndex]) {
              if (userSelected[mealIndex][n.paxId] && n.amount > 0) {
                if (userSelected[mealIndex][n.paxId][e3.code]) {
                  userSelected[mealIndex][n.paxId][e3.code] =
                    (userSelected[mealIndex][n.paxId][e3.code] ?? 0) + n.amount;
                } else {
                  userSelected[mealIndex][n.paxId][e3.code] = n.amount;
                }
              }
            } else {
              if (n.amount > 0) {
                userSelected.push({
                  [n.paxId]: {
                    [e3.code]: n.amount,
                  },
                });
              }
            }
          });
        });
      e1.arItems.purchased
        .filter((e2) => e2.type === EArItemType.MEAL)
        .forEach((e3) => {
          e3.byPax?.forEach((n) => {
            noMeal = false;
            const mealIndex = userSelected.findIndex((e) => e[n.paxId]);
            if (mealIndex > -1 && userSelected[mealIndex]) {
              if (userSelected[mealIndex][n.paxId] && n.amount > 0) {
                if (userSelected[mealIndex][n.paxId][e3.code]) {
                  userSelected[mealIndex][n.paxId][e3.code] =
                    (userSelected[mealIndex][n.paxId][e3.code] ?? 0) + n.amount;
                } else {
                  userSelected[mealIndex][n.paxId][e3.code] = n.amount;
                }
              }
            } else {
              if (n.amount > 0) {
                userSelected.push({
                  [n.paxId]: {
                    [e3.code]: n.amount,
                  },
                });
              }
            }
          });
        });
    });
    mealSummary.push({
      label: getTripTitle(e?.index ?? idx, tripType),
      userSelected,
    });
  });
  return { mealSummary, noMeal };
}
