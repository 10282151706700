import { EPaxType } from "@hkexpressairwayslimited/ui";
import { getFlightRoute } from "lib/features/flight-book/booking/service";
import { getAllSSR, getSeats } from "lib/features/flight-book/payment/helper";
import { CurrencyDetail } from "modules/global/currency/d";
import store from "store";
import {
  OnlineCheckInPassengersType,
  OnlineCheckInTripDetailType,
} from "store/sessionStorage/slices/onlineCheckInSlice";
import { ManageMyBookingTripDetail } from "../my-trips/definition";
import { APICreateOrderRequestBody } from "./definition";

export function constructCheckInList(
  journeyKey: string | null,
  trip: (Omit<ManageMyBookingTripDetail, "passengers"> & { passengers: any[] }) | undefined
) {
  if (trip && journeyKey !== null) {
    const targetJourney = trip.journeys.find((journey) => journey.journey_key === journeyKey);
    if (targetJourney) {
      const filterAdultList = trip.passengers.filter((passenger) => passenger.paxType === EPaxType.Adult);
      const filterChildList = trip.passengers.filter((passenger) => passenger.paxType === EPaxType.Child);
      const filterInfantList = trip.passengers.filter((passenger) => passenger.paxType === EPaxType.Infant);
      const filterExtList = trip.passengers.filter((passenger) => passenger.paxType === "EXT");
      const adultList = filterAdultList.map((passenger, index) => {
        const curInfant = filterInfantList.find((inf) => inf?.associated_adult_passenger_key === passenger.id);
        const infantLabel = curInfant ? `${curInfant.courtesy} ${curInfant.surname} ${curInfant.givenName}` : "";
        return {
          ...passenger,
          label: `${passenger.courtesy} ${passenger.surname} ${passenger.givenName}${infantLabel ? " - " + infantLabel : ""}`,
          disable: targetJourney.eligibilities.find((e) => e.passenger_key === passenger.id)?.olci_eligible === false,
          value: false,
          ...(curInfant
            ? {
                infantDetail: curInfant,
              }
            : {}),
        };
      });
      const childList = filterChildList.map((passenger) => {
        return {
          ...passenger,
          label: `${passenger.courtesy} ${passenger.surname} ${passenger.givenName}`,
          disable:
            targetJourney.eligibilities.find((e) => e.passenger_key === passenger.id)?.olci_eligible === false ||
            passenger.id === "INF",
          value: false,
        };
      });
      const extList = filterExtList.map((passenger) => {
        return {
          ...passenger,
          label: `${passenger.courtesy} ${passenger.surname} ${passenger.givenName}`,
          disable: targetJourney.eligibilities.find((e) => e.passenger_key === passenger.id)?.olci_eligible === false,
          value: false,
        };
      });
      return [...adultList, ...childList, ...extList];
    }
  }
  return [];
}

export const constructOrderCreationRequestBody = async (
  mileAmount: number,
  cash_amount: number,
  infant_total_amount: number,
  currency: string,
  mcp: any,
  insurance: number,
  carbon_offset: number
): Promise<APICreateOrderRequestBody> => {
  const tripParams = JSON.parse(typeof window !== "undefined" ? sessionStorage.getItem("tripParams") ?? "{}" : "{}");
  const passengerDetail: OnlineCheckInPassengersType[] = store.getState().session.onlineCheckIn.onlineCheckInPassengers;
  const tripDetail: OnlineCheckInTripDetailType = store.getState().session.onlineCheckIn.onlineCheckInTripDetail;
  // const uoUsername = parseJwt(store.getState().auth.accessToken).username;
  const ssrs = getAllSSR(tripDetail);
  const sales_port_grouping = (await getFlightRoute()).sales_port_grouping;
  const trips = store
    .getState()
    .session.manageMyBooking.trips?.find((trip: any) => trip.sales_reference === tripParams?.bookingRef);
  return {
    sales_channel: "ONLINE",
    sales_market:
      sales_port_grouping.find((route: any) =>
        route.ports.some((port: any) => port.airport_code === trips?.journeys?.[0]?.origin)
      )?.market ?? "",
    sales_port: trips?.journeys?.[0]?.origin,
    sales_reference: tripParams?.bookingRef,
    // payment_currency_code: currency,
    selected_currency_code: currency,
    customer_country: trips?.contact?.country ?? trips.passengers[0]?.country ?? "",
    cash_amount: cash_amount,
    mile_amount: mileAmount ?? 0,
    // application_code: "OLCI",
    // uo_username: uoUsername,
    cx_member_number: null,
    trip_type:
      trips.journeys.length === 1
        ? "OW"
        : trips.journeys[0].origin === trips.journeys[1].destination
          ? "RT"
          : "COMPLEX",
    passengers: passengerDetail
      .filter((passenger) => passenger.paxType !== EPaxType.Infant)
      .map((passenger) => ({
        passenger_key: passenger.id,
        seats: getSeats(passenger.id, tripDetail),
      })),
    ssrs: ssrs,
    insurance: insurance
      ? {
          item_amount: insurance,
        }
      : null,
    carbon_offset: carbon_offset ? { item_amount: carbon_offset } : null,
    // total: {
    //   amount: tripCost,
    //   currency: trips.journeys[0].fare.currency_code,
    //   label: "Grand total (including taxes)",
    // },
  };
};

export const constructOnlineCheckInOrderValidationBody = async (
  tripDetail: OnlineCheckInTripDetailType,
  bookingTrip: ManageMyBookingTripDetail,
  passengers: OnlineCheckInPassengersType[]
) => {
  // const uoUsername = parseJwt(store.getState().auth.accessToken).username;
  const { selectedCurrency }: CurrencyDetail = store.getState().session.currency;
  const ssrs = getAllSSR(tripDetail);
  const flightRoute = await getFlightRoute();
  const body = {
    // cx_member_number: null,
    // uo_username: uoUsername,
    sales_market:
      flightRoute.sales_port_grouping.find((route: any) =>
        route.ports.some((port: any) => port.airport_code === bookingTrip.journeys[0].origin)
      )?.market ?? "",
    sales_reference: bookingTrip.sales_reference,
    trip_type:
      bookingTrip.journeys.length === 1
        ? "OW"
        : bookingTrip.journeys[0].origin === bookingTrip.journeys[1].destination
          ? "RT"
          : "COMPLEX",
    selected_currency_code: selectedCurrency,
    customer_country: bookingTrip.contact?.country ?? bookingTrip.passengers[0]?.country ?? "",
    application_code: "MMB",
    passengers: passengers
      .filter((passenger) => passenger.paxType !== EPaxType.Infant)
      .map((passenger) => ({
        passenger_key: passenger.id,
        seats: getSeats(passenger.id, tripDetail),
      })),
    ssrs: ssrs,
  };

  return { ...body };
};
