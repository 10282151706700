import { getCookieDomain } from "@hkexpressairwayslimited/ui";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { CurrencyDetail, CurrencyRate } from "modules/global/currency/d";
import { RootState } from "store";

const initialState: CurrencyDetail = {
  selectedCurrency: "",
  bookingCurrency: "",
  rate: [],
  asiaMiles: false,
};

export const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    storeCurrencyDetail: (state, action: PayloadAction<Partial<CurrencyDetail & { trace_id: string }>>) => {
      if (action.payload.selectedCurrency !== undefined) {
        Cookies.set("currency", action.payload.selectedCurrency, {
          domain: getCookieDomain(),
          path: "/",
          expires: 365,
        });
      }
      Object.assign(state, action.payload);
    },
    storeSelectedCurrency: (state, action: PayloadAction<string>) => {
      if (action.payload !== undefined) {
        Cookies.set("currency", action.payload, { domain: getCookieDomain(), path: "/", expires: 365 });
      }
      state.selectedCurrency = action.payload;
    },
    storeBookingCurrency: (state, action: PayloadAction<string>) => {
      state.bookingCurrency = action.payload;
    },
    storeCurrencyRate: (state, action: PayloadAction<CurrencyRate[]>) => {
      state.rate = action.payload;
    },
  },
  selectors: {
    selectCurrency: (state): string => state.selectedCurrency,
    selectCurrencyRate: (state): CurrencyRate[] => state.rate,
    selectBookingCurrency: (state): string => state.bookingCurrency,
    selectCurrencyDetail: (state): CurrencyDetail => state,
  },
});

export const { storeCurrencyDetail, storeSelectedCurrency, storeBookingCurrency, storeCurrencyRate } =
  currencySlice.actions;
export default currencySlice.reducer;
export const { selectCurrency, selectCurrencyRate, selectBookingCurrency, selectCurrencyDetail } =
  currencySlice.getSelectors((state: RootState) => {
    return state.session.currency;
  });
