import { ArItem, TripDetail } from "@hkexpressairwayslimited/ui";
import { endpoints, fetchAPI } from "api";
import { isBefore } from "date-fns";
import { requiredHeader } from "lib/common/service";
import { closeLoadingBackdrop } from "modules/common/loading-backdrop/actions/LoadingBackdropAction";
import { RouterInstance } from "router/router-utils";
import store from "store";
import { resetStep1 } from "store/sessionStorage/slices/AMPortal";
import { storeErrorModalDetail } from "store/sessionStorage/slices/errorModalDetail";
import { EArItemType } from "ui/features/flight-booking/add-extras";
import { LowFare } from "ui/features/flight-booking/flight-select/date-slider-selector/definition";
import {
  APIFlightAvailability,
  APIRequestBodyLowFareAndFlightSearch,
  getSSRInfoProps,
} from "../flight-book/flight-select/definition";
import { calculateDayDifference, formateDate, formateTime } from "../flight-book/flight-select/helper";
import {
  APICreateOrderRequestBody,
  APICreateOrderValidationRequestBody,
  ErrorType,
  responseApiError,
} from "../flight-book/payment/definition";
import { CXMemberInfoResp } from "./d";
import { ErrorCodeList, getErrorDetail } from "./errorCodeMapping";

export const getCXMemberInfo = async (): Promise<CXMemberInfoResp> => {
  return await fetchAPI(endpoints.apiDomain + "/member/v1/member-info", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
    },
    cache: "no-store",
    body: "{}",
  });
};

export const getFlightAvailability = async (data: APIRequestBodyLowFareAndFlightSearch) => {
  // const token = await extendNskToken(store.getState().session.nskToken.nsk_token);
  const result = await fetchAPI(endpoints.apiDomain + "/flt-booking-query/public/v1/am-portal/availability/search", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
    },
    cache: "no-store",
    body: JSON.stringify(data),
  });

  const newResult: TripDetail[] = result.error_code
    ? []
    : result.trip.map((trip: APIFlightAvailability) => {
        return {
          journeys:
            trip.journey.length > 0
              ? trip.journey
                  .map((journey) => {
                    const minutes =
                      journey.duration.minute && journey.duration.minute > 0 ? `${journey.duration.minute}m` : "";
                    return {
                      tripOrigin: trip.origin.airport_code,
                      tripDestination: trip.destination.airport_code,
                      arrival_date_time: journey.arrival_date_time,
                      departure_date_time: journey.departure_date_time,
                      journey_key: journey.journey_key,
                      fare: journey.fare,
                      origin: journey.segment[0].origin,
                      destination: journey.segment[journey.segment.length - 1].destination,
                      date: formateDate(journey.departure_date_time),
                      day_diff: calculateDayDifference(journey.departure_date_time, journey.arrival_date_time),
                      duration: journey.duration,
                      segments: journey.segment.map((segment) => {
                        return {
                          segment_key: segment.segment_key,
                          flight_number: segment.flight_number,
                          destination: segment.destination,
                          origin: segment.origin,
                          arrival: segment.arrival_date_time,
                          arrival_time: formateTime(segment.arrival_date_time),
                          arrival_date: formateDate(segment.arrival_date_time),
                          departure: segment.departure_date_time,
                          departure_time: formateTime(segment.departure_date_time),
                          departure_date: formateDate(segment.departure_date_time),
                          arrival_timezone: segment.arrival_timezone,
                          departure_timezone: segment.departure_timezone,
                          duration: segment.duration,
                          is_ferry: segment.is_ferry,
                          legs: segment.legs,
                          origin_airport_terminal: segment.origin_airport_terminal,
                          destination_airport_terminal: segment.destination_airport_terminal,
                          arItems: {
                            available: [],
                            selected: [],
                            purchased: [],
                          },
                        };
                      }),
                      is_promoted: journey.is_promoted,
                      display_item_amount: journey.display_item_amount,
                      flight_time: `${journey.duration.hour}h ${minutes}`,
                      arItems: {
                        available: [],
                        selected: [],
                        purchased: [],
                      },
                    };
                  })
                  .sort((a, b) => (isBefore(new Date(a.departure_date_time), new Date(b.departure_date_time)) ? -1 : 1))
              : [{ index: -1, tripOrigin: trip.origin.airport_code, tripDestination: trip.destination.airport_code }],
          arItems: {
            available: [],
            selected: [],
            purchased: [],
          },
        };
      });

  return newResult;
};

export const getLowFare = async (
  data: APIRequestBodyLowFareAndFlightSearch
): Promise<{
  trip: {
    destination: string;
    origin: string;
    low_fare: LowFare[];
  }[];
}> => {
  const result = await fetchAPI(endpoints.apiDomain + "/flt-booking-query/public/v1/am-portal/low-fare/availability", {
    method: "POST",
    cache: "no-store",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
    },
    body: JSON.stringify(data),
  });

  return result;
};

export const getSSRInfo = async (
  data: getSSRInfoProps
): Promise<{
  remappedResult: any;
  simCard: ArItem[];
}> => {
  const result = await fetchAPI(endpoints.apiDomain + "/flt-booking-query/v1/am-portal/ssr-booking", {
    method: "POST",
    cache: "no-store",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      nsk_token: store.getState().session.nskToken.nsk_token,
    },
    body: JSON.stringify(data),
  });

  let simCard: ArItem[] = [];

  result.journeys[0].ssrs
    .map((ssr: any) => {
      if (ssr.type === EArItemType.SIM_CARD && ssr.is_offer && ssr.product.length > 0)
        simCard = ssr.product.map((ar: any) => ({
          type: ssr.type.toUpperCase(),
          code: ar.ssr_code,
          price: ar.ssr_by_passengers[0].price,
          availability: ar.available,
          currency_code: ar.currency_code,
          ssr_by_passengers: ar.ssr_by_passengers,
          limit_per_passenger: ar.limit_per_passenger,
        }));
    })
    .flat();

  const remappedResult = result.journeys.map((journey: any, i: number) => {
    return {
      ...journey,
      ssrs: journey.ssrs
        .flatMap((ssr: any) => {
          if (ssr.is_offer && ssr.type !== EArItemType.SIM_CARD)
            return ssr.product.map((ar: any) => {
              return {
                type: ssr.type.toUpperCase(),
                code: ar.ssr_code,
                price: ar.ssr_by_passengers[0].price,
                availability: ar.available,
                cms_content_key: ar.cms_content_key,
                currency_code: ar.currency_code,
                ssr_by_passengers: ar.ssr_by_passengers,
                limit_per_passenger: ar.limit_per_passenger,
              };
            });
        })
        .filter(Boolean),
      segments: journey.segments.map((segment: any) => {
        return {
          ...segment,
          ssrs:
            segment.ssrs !== null &&
            segment.ssrs
              .flatMap((ssr: any) => {
                if (ssr.is_offer)
                  return ssr.product.map((ar: any) => ({
                    type: ssr.type.toUpperCase(),
                    code: ar.ssr_code,
                    price: ar.ssr_by_passengers[0].price,
                    availability: ar.available,
                    cms_content_key: ar.cms_content_key,
                    currency_code: ar.currency_code,
                    ...(ar.is_pre_order !== undefined && { is_pre_order: ar.is_pre_order }),
                    ...(ar.is_vegetarian !== undefined && { is_vegetarian: ar.is_vegetarian }),
                    ssr_by_passengers: ar.ssr_by_passengers,
                    limit_per_passenger: ar.limit_per_passenger,
                  }));
              })
              .filter(Boolean),
        };
      }),
    };
  });
  return { remappedResult, simCard };
};

export const orderValidation = async (data: APICreateOrderValidationRequestBody) => {
  const result = await fetchAPI(
    endpoints.manageDomain + "/order/v1/order/am-portal/validation",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...requiredHeader(),
        nsk_token: store.getState().session.nskToken.nsk_token,
      },
      body: JSON.stringify(data),
      errorCodeWhitelist: ErrorCodeList,
    },
    true
  ).catch((error: responseApiError) => {
    const errorDetail = getErrorDetail(error);
    if (errorDetail) {
      store.dispatch(storeErrorModalDetail(errorDetail));
    } else {
      store.dispatch(
        storeErrorModalDetail({
          type: ErrorType.M,
          title: `${error.error_code}.title`,
          desc: `${error.error_code}.desc`,
          onClose: () => {
            //dispatch()
            store.dispatch(resetStep1());
            RouterInstance.replace("/:lang/full-miles-redemption/booking/search");
          },
        })
      );
    }
  });
  return result;
};

export const createOrder = async (data: APICreateOrderRequestBody) => {
  const result = await fetchAPI(
    endpoints.manageDomain + "/order/v1/am-portal/order",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...requiredHeader(),
        nsk_token: store.getState().session.nskToken.nsk_token,
      },
      body: JSON.stringify(data),
      errorCodeWhitelist: ErrorCodeList,
      captcha: true,
    },
    true
  ).catch((error: responseApiError) => {
    const errorDetail = getErrorDetail(error);
    if (errorDetail) {
      store.dispatch(closeLoadingBackdrop());
    } else {
      store.dispatch(
        storeErrorModalDetail({
          type: ErrorType.M,
          title: `${error.error_code}.title`,
          desc: `${error.error_code}.desc`,
          onClose: () => {
            //dispatch()
            store.dispatch(resetStep1());
            RouterInstance.replace("/:lang/full-miles-redemption/booking/search");
          },
        })
      );
    }
  });

  return result;
};
